export const WebPortfolio = [
    {
        "title": "Shoelace App \"19",
        "info": "The latest web application iteration to help facilitate a better understanding for Shoelace's prime product, Journeys.",
        "urlImage": "images/olliefolio/preview/sholeaceapp2.jpg",
        "urlDribble": "https://www.behance.net/gallery/72463045/Shoelace-Product-19",
        "urlDemo": "#"
    },
    {
        "title": "Shoelace App \"17 - \"19",
        "info": "This web application implemented Shoelace's featured products. Journeys and Nudges: To help inform customers of the offering Shoelace provides.",
        "urlImage": "images/olliefolio/preview/nudgesv1.jpg",
        "urlDribble": "https://www.behance.net/gallery/72463125/Shoelace-Product-17-18",
        "urlDemo": "#"
    },
    {
        "title": "Shoelace Corporate \"18",
        "info": "The Shopify app continues to move up market as a advertising retargeting only company and rebrands its site to accommodate more innovations.",
        "urlImage": "images/olliefolio/preview/shoelacecorp2.jpg",
        "urlDribble": "https://www.behance.net/gallery/72462903/Shoelace-Corporate-18",
        "urlDemo": "#"
    },
    {
        "title": "Shoelace Corporate \"17",
        "info": "Shoelace's first brand website after creating Journey Retargeting. Taking visitors through a brand experience after leaving your Shopify store.",
        "urlImage": "images/olliefolio/preview/shoelacecorp1.jpg",
        "urlDribble": "https://www.behance.net/gallery/72462941/Shoelace-Corporate-17",
        "urlDemo": "#"
    },
    {
        "title": "Yonge Street Living Residences",
        "info": "From coveted locations to fashion-forward design to sophisticated amenities, Cresford defines the essence of luxurious condominiums.",
        "urlImage": "images/olliefolio/preview/yongestreetliving.jpg",
        "urlDribble": "https://www.behance.net/gallery/72463995/Yonge-Street-Living-Residences",
        "urlDemo": "#"
    },
    {
        "title": "Palico App for Monster Hunter: World",
        "info": "A full web application for connecting gamers to the latest world events, news articles and user generated content for Monster Hunter: World.",
        "urlImage": "images/olliefolio/preview/palico.jpg",
        "urlDribble": "#",
        "urlDemo": "#"
    },
    {
        "title": "Voodoo",
        "info": "Taking health and wellness into your own hands. Track your trauma and discomfort and we'll find solutions. Your health, your way.",
        "urlImage": "images/olliefolio/preview/Voodoo.jpg",
        "urlDribble": "https://www.behance.net/gallery/49825689/Voodoo-Health-Wellness-Landing-Page",
        "urlDemo": "#"
    },
    {
        "title": "Pixel Unity",
        "info": "A virtual reality company that focuses on virtual reality solutions for all VR-platforms. The team creates VR games, entertainment and interactive sites.",
        "urlImage": "images/olliefolio/preview/PixelUnity.jpg",
        "urlDribble": "#",
        "urlDemo": "#"
    },
    {
        "title": "Playcade Inc.",
        "info": "Toronto's virtual reality arcade and cafe. It focuses on bringing together friends and family to enjoy the new world of virtual reality entertainment!",
        "urlImage": "images/olliefolio/preview/Playcade.jpg",
        "urlDribble": "#",
        "urlDemo": "#"
    },
    {
        "title": "Go Companion",
        "info": "A mobile iOS and Android tool for an emerging game called Pokemon Go that works side by side providing information about Pokemon location and guidance!",
        "urlImage": "images/olliefolio/preview/GoCompanion_Preview.jpg",
        "urlDribble": "#",
        "urlDemo": "#"
    },
    {
        "title": "Pixel Bulletin",
        "info": "Pixel Bulletin is an informative start-up blog / news site that focuses on emerging gaming trends, geek culture and entertainment!",
        "urlImage": "images/olliefolio/preview/PixelBulletin.jpg",
        "urlDribble": "https://www.behance.net/gallery/49805585/Pixel-Bulletin",
        "urlDemo": "#"
    },
    {
        "title": "Grand Slam Media",
        "info": "Grand Slam Media is a global company focused on improving the communication threshold between buyers and sellers of online advertising.",
        "urlImage": "images/olliefolio/preview/GSM.jpg",
        "urlDribble": "https://www.behance.net/gallery/49805603/G-S-M",
        "urlDemo": "#"
    },
    {
        "title": "Adnium Concept",
        "info": "A concept design with a very minimal approach in communicating to new users the simplicity and ease of using the ad network.",
        "urlImage": "images/olliefolio/preview/AdniumConcept.jpg",
        "urlDribble": "https://www.behance.net/gallery/49805487/Ad_Concept2",
        "urlDemo": "#"
    },
    {
        "title": "Adnium Landing",
        "info": "A brand new feature called Retargeting. It required an informative and easy to read page that explained how it worked and how to get started.",
        "urlImage": "images/olliefolio/preview/AdniumRetargeting.jpg",
        "urlDribble": "https://www.behance.net/gallery/49805389/Ad_Target",
        "urlDemo": "#"
    },
    {
        "title": "Adnium Rebrand",
        "info": "A new look that focuses on subtle interactive animations, a clean minimalistic feel and a professional look that provides a sense of reliability.",
        "urlImage": "images/olliefolio/preview/AdniumV2.jpg",
        "urlDribble": "https://www.behance.net/gallery/49805329/AD_Concept",
        "urlDemo": "#"
    },
    {
        "title": "Adnium Prototype",
        "info": "Apart of the new rebranding effort, this prototype's goal was to focus on the technologies inside the network in effort to convert visitors into customers.",
        "urlImage": "images/olliefolio/preview/Adnium_Proto.jpg",
        "urlDribble": "https://www.behance.net/gallery/49805523/Ad_V3",
        "urlDemo": "#"
    },
    {
        "title": "Adnium Home",
        "info": "It is recognized as one of the top competitive self-serve ad platforms in the world. Focusing on providing customers & an edge in traffic.",
        "urlImage": "images/olliefolio/preview/Adnium_V1.jpg",
        "urlDribble": "#",
        "urlDemo": "#"
    },
    {
        "title": "Adnium Support",
        "info": "The rebranded version of the ad network's HelpDesk. It allows users to quickly solve their problem via FAQ, helpful videos and customer support.",
        "urlImage": "images/olliefolio/preview/Adnium_Support.jpg",
        "urlDribble": "https://www.behance.net/gallery/49805433/Ad_Support",
        "urlDemo": "#"
    },
    {
        "title": "Adnium Dasbboard",
        "info": "The heart of the ad network is the Dashboard used by hundreds of advertisers on a daily basis with a simple UI & powerful features.",
        "urlImage": "images/olliefolio/preview/Adnium_Dash.jpg",
        "urlDribble": "https://www.behance.net/gallery/49805687/Ad_Dash",
        "urlDemo": "#"
    },
    {
        "title": "Adnium 404",
        "info": "The 404 page, also known as, the 'NotFound' page. Instead of the traditional 404, a cute animated dog in space was used as a replacement.",
        "urlImage": "images/olliefolio/preview/Adnium404.jpg",
        "urlDribble": "#",
        "urlDemo": "#"
    },
    {
        "title": "Gao Restaurant",
        "info": "A family restaurant founded and owned by Mr.Gao serves wonderfully crafted Chinese cuisine in the city of Hamilton for everyone to enjoy.",
        "urlImage": "images/olliefolio/preview/GaoRestaurant.jpg",
        "urlDribble": "https://www.behance.net/gallery/9091589/Traditional-Chinese-Restaurant",
        "urlDemo": "#"
    },
    {
        "title": "Star Wars: The Old Republic",
        "info": "Star Wars: The Old Republic is a MMORPG. The strongest guild during the launch required a community site featuring a guides section & a forum.",
        "urlImage": "images/olliefolio/preview/SWTOR.jpg",
        "urlDribble": "https://www.behance.net/gallery/9365439/Star-Wars-the-Old-Republic",
        "urlDemo": "#"
    },
    {
        "title": "Anime Flask",
        "info": "Its predecessor ACN (Anime Community Network) wanted a hub for Anime to be shared via: streaming, P2P-sharing, community forum & file downloading",
        "urlImage": "images/olliefolio/preview/AnimeFlask.jpg",
        "urlDribble": "#",
        "urlDemo": "#"
    },
    {
        "title": "McMaster University",
        "info": "McMaster's Badminton Club is a prestigious University with high caliber players that competed in high-ranking tournaments throughout the nation.",
        "urlImage": "images/olliefolio/preview/McM.jpg",
        "urlDribble": "https://www.behance.net/gallery/23555677/McMaster-University-Badminton",
        "urlDemo": "#"
    },
    {
        "title": "Fungki Salon",
        "info": "A salon that focuses on professional haircuts and styling. They work on theatrical runway shows on the red carpet and dramatic fashion shows.",
        "urlImage": "images/olliefolio/preview/Fungki.jpg",
        "urlDribble": "https://www.behance.net/gallery/23554745/Fungki-Hair-Salon-Fashion",
        "urlDemo": "#"
    },
    {
        "title": "Mythic Merchant",
        "info": "A North American online shop that focuses on bohemian clothing, lifestyle, accessories and jewellery. A simple design with an e-commerce solution.",
        "urlImage": "images/olliefolio/preview/MythicMerchant.jpg",
        "urlDribble": "https://www.behance.net/gallery/23571205/Mythic-Merchant-Wordpress-Theme-eCommerce",
        "urlDemo": "#"
    },
    {
        "title": "CNT Stone & Granite",
        "info": "A fabrication company that allows customers to choose from a variety of stones and finishes to make their personal style come to life at home.",
        "urlImage": "images/olliefolio/preview/CNT.jpg",
        "urlDribble": "https://www.behance.net/gallery/9093939/CNT-Stone-Granite",
        "urlDemo": "#"
    },
    {
        "title": "Kiono Gaming",
        "info": "Her popularity as a streamer surged streaming League of Legends content. An enjoyable person to watch as she cleaves through enemies without realizing it.",
        "urlImage": "images/olliefolio/preview/Kiono.jpg",
        "urlDribble": "https://www.behance.net/gallery/23382309/Wordpress-Theme-Gaming-Commuity-Hub",
        "urlDemo": "#"
    },
    {
        "title": "Jungle Timer",
        "info": "A League of Legends tool to assist users via website of monster-spawn events that are known to give players a buff advatange over other players.",
        "urlImage": "images/olliefolio/preview/JungleTimer.jpg",
        "urlDribble": "#",
        "urlDemo": "#"
    },
    {
        "title": "Jamsterdam Radio",
        "info": "A hit Internet radio station featuring underground artists throughout North America. It provided a place for artists to advertise their music.",
        "urlImage": "images/olliefolio/preview/Jamsterdam.jpg",
        "urlDribble": "https://www.behance.net/gallery/49805631/Jamsterdam-Radio",
        "urlDemo": "#"
    }
];

const graphicUrl = "images/olliefolio/previewnews/";
export const GraphicDesigns = [
    {
        "image": graphicUrl + "KeywordTargeting.jpg",
        "title": "Keyword Targeting"
    },
    {
        "image": graphicUrl + "Adnium_LosVegas.jpg",
        "title": "Adnium LA"
    },
    {
        "image": graphicUrl + "GSM_Adnium_LA_LasVegas.jpg",
        "title": "Adnium and Grand Slam Media LA"
    },
    {
        "image": graphicUrl + "CNE_Toronto_VR.jpg",
        "title": "Playcade CNE Toronto"
    },
    {
        "image": graphicUrl + "XBIZExec_CEO.jpg",
        "title": "XBIZ Executive Awards for CEO"
    },
    {
        "image": graphicUrl + "XIBZExec_Tech.jpg",
        "title": "XBIZ Executive Awards for Tech Leadership"
    },
    {
        "image": graphicUrl + "YNOT_Awards.jpg",
        "title": "YNOT Awards"
    },
    {
        "image": graphicUrl + "MobilePopUnder_YouJizz.jpg",
        "title": "YouJizz Mobile Pop Under"
    },
];

const publicolioUrl = "images/olliefolio/previewpub/";
export const Publications = [
    {
        "image": publicolioUrl + "XBIZCover_1.jpg",
        "title": "XBIZ Cover September Issue",
        "url": "https://www.behance.net/gallery/49826577/XBIZ-World-Magazine-2015-2016"
    },
    {
        "image": publicolioUrl + "XBIZCover_2.jpg",
        "title": "XBIZ Cover January Issue",
        "url": "https://www.behance.net/gallery/49826523/XBIZ-World-Magazine-Cover"
    },
    {
        "image": publicolioUrl + "Pub_Advert_Preview.jpg",
        "title": "XBIZ Advertiser and Publishers",
        "url": "https://www.behance.net/gallery/49826643/Return-on-Investment-Matters"
    },
    {
        "image": publicolioUrl + "XBIZ_Awards_Preview.jpg",
        "title": "XBIZ Awards",
        "url": "https://www.behance.net/gallery/49826605/XBIZ-Awards-2016"
    },
    {
        "image": publicolioUrl + "Adnium_Informative_Light.jpg",
        "title": "XBIZ Adnium Infographic",
        "url": "https://www.behance.net/gallery/49826451/3-Shocking-Things-About-Ad-Networks"
    },
    {
        "image": publicolioUrl + "Adnium_Informative_Dark.jpg",
        "title": "XBIZ Adnium Infographic",
        "url": "https://www.behance.net/gallery/49826395/3-Shocking-Things-About-Ad-Networks"
    },
];