import React from 'react'
import { Navigation, Heading, Socials } from './Header';
import { GetToKnowMe, Philosophy, Toolkit, Mail, CallToAction } from './Body';
import Footer from './Footer';

class Home extends React.Component {
    render() {
        return (
            <div id="application">
                <header>
                    <Navigation />
                    <Heading />
                    <Socials />
                </header>

                <div>
                    <GetToKnowMe />
                    <Philosophy />
                    <Toolkit />
                    <Mail />
                    <CallToAction />
                </div>

                <footer>
                    <Footer />
                </footer>
            </div>
        );
    }
}

export default Home;