import React from 'react';
import { Mutation, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import SimpleReactValidator from 'simple-react-validator';
import ReCAPTCHA from 'react-google-recaptcha';

const Tools = [
    {
        id: 1,
        src: "images/Icon_Git.png",
        alt: "Built with Git",
        paragraph: "You can't Git away from version control. It is a necessary tool for any dev."
    },
    {
        id: 2,
        src: "images/Icon_Slack.png",
        alt: "Communicate via Slack",
        paragraph: "Slack, a daily way of life to stay connected to team members."
    },
    {
        id: 3,
        src: "images/Icon_VisualStudio.png",
        alt: "Visual Studio IDE 2017",
        paragraph: "VS2017 is a solid coding tool throughout the entire development lifecycle."
    },
    {
        id: 4,
        src: "images/Icon_Webpack.png",
        alt: "Built with Webpack",
        paragraph: "Webpack is an essential part of my build step for optimization."
    },
];

const EmailService = gql`
    mutation EmailService($name: String!, $email: String!, $company: String, $website: String, $message: String!) {
        emailService(name: $name, email: $email, company: $company, website: $website, message: $message)
    }
`;

class Mail extends React.Component {
    constructor() {
        super();
        this.validator = new SimpleReactValidator();
    }

    state = {
        name: '',
        email: '',
        company: '',
        website: '',
        message: '',
        formPosition: 0,
        captchaComplete: false
    };

    changeState = (key, value) => {
        this.setState({
            [`${key}`]: value
        })
    };

    submitIntro = () => {
        if (
            this.validator.fieldValid('name') &&
            this.validator.fieldValid('email')
        ) {
            this.setState({
                formPosition: 1
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    submitMessage = () => {
        if (this.validator.fieldValid('message')) {
            this.setState({
                formPosition: 2
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    verifyCaptcha = response => {
        if (response) {
            this.setState({
                captchaComplete: true
            })
        }
    };

    render() {
        const { name, email, company, website, message } = this.state;
        return (
            <section className="mail">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 data-aos="fade-down">You bring the idea, I'll do the rest</h2>
                                    <h3 data-aos="fade-in">Delivering on web & mobile applications that evoke engaging emotional responses. Innovative thinking and attention to detail are the hallmark of my craft.</h3>
                                </div>
                                {Tools.map(tool => {
                                    return (
                                        <div className="col-lg-6 col-md-6 iconRevolve" key={tool.id} data-aos="fade-in">
                                            <img src={tool.src} alt={tool.alt}/>
                                            <p>{tool.paragraph}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-0 col-md-10 offset-md-1">
                            <aside id="mail">
                                <div id="ollieForm" className="form-container">
                                    <fieldset className={`${this.state.formPosition === 0 ? 'd-block' : 'd-none'}`}>
                                        <div className="text-center mb-4">
                                            <h4 data-aos="fade-down">Say Hello! <i className="fa fa-smile-o"/></h4>
                                            <h5 data-aos="fade-in">Let us get to know each other</h5>
                                        </div>
                                        <div className="form-group">
                                            <input value={name} onChange={event => this.changeState('name', event.target.value)} placeholder="Name (required)" className="form-control" data-aos="fade-in"/>
                                            {this.validator.message('name', name, 'required|min:6')}
                                        </div>
                                        <div className="form-group">
                                            <input value={email} onChange={event => this.changeState('email', event.target.value)} placeholder="Email Address (required)" className="form-control" data-aos="fade-in" type="email"/>
                                            {this.validator.message('email', email, 'required|email')}
                                        </div>
                                        <div className="form-group">
                                            <input value={company} onChange={event => this.changeState('company', event.target.value)} placeholder="Company" className="form-control" data-aos="fade-in"/>
                                            {this.validator.message('company', company, 'min:4')}
                                        </div>
                                        <div className="form-group">
                                            <input value={website} onChange={event => this.changeState('website', event.target.value)} placeholder="Website" className="form-control" type="url" data-aos="fade-in"/>
                                            {this.validator.message('website', website, 'url|min:6')}
                                        </div>
                                        <button type="button" onClick={this.submitIntro} className="btn btn-block btn-primary" data-aos="fade-in">Next</button>
                                    </fieldset>

                                    <fieldset className={`${this.state.formPosition === 1 ? 'd-block' : 'd-none'}`}>
                                        <h4>Hello <span id="name">Friend!</span></h4>
                                        <h5>Your message:</h5>
                                        <textarea
                                            value={message}
                                            onChange={event => this.changeState('message', event.target.value)}
                                            placeholder="Hello, I wanted to get in touch with you!"
                                            className="form-control"
                                        />
                                        {this.validator.message('message', message, 'required|min:10|max:500')}
                                        <button type="button" onClick={this.submitMessage} className="btn btn-block btn-primary mt-3">Next</button>
                                    </fieldset>

                                    <Mutation mutation={EmailService}>
                                        {(emailService, { loading, error, data }) => (
                                            <fieldset className={`${this.state.formPosition === 2 ? 'd-block' : 'd-none'} text-center`}>
                                                <h4 style={{ fontSize: '33px' }}>Complete the captcha <i className="fa fa-smile-o" /></h4>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <ReCAPTCHA onChange={this.verifyCaptcha} sitekey="6LfA5xQUAAAAAJUaFABc-apu3X1xujkfuaNMN027" />
                                                </div>
                                                <h5>We're almost there!</h5>
                                                {!this.state.captchaComplete ? (
                                                    <button type="button" className="btn btn-outline-primary btn-block mt-3" disabled={true}>Submit</button>
                                                ) : (!loading && !data && !error) ? (
                                                    <button onClick={() => emailService({ variables: { name, email, company, website, message } })} type="button" className="btn btn-primary btn-block mt-3">Submit</button>
                                                ) : (
                                                    <div>
                                                        {loading && <button type="button" className="btn btn-primary btn-block mt-3" disabled={true}><i className="fas fa-spinner fa-spin"/> Sending..</button>}
                                                        {error && <button onClick={() => emailService({ variables: { name, email, company, website, message } })} type="button" className="btn btn-primary btn-block mt-3">Retry</button>}
                                                        {data && <button type="button" className="btn btn-primary btn-block mt-3" disabled={true}>Success</button>}
                                                    </div>
                                                )}
                                            </fieldset>
                                        )}
                                    </Mutation>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withApollo(Mail);