import React from 'react';

class Logo extends React.Component {
    render() {
        return (
            <svg className="d-inline-block align-top" xmlns="http://www.w3.org/2000/svg" version="1.0" width="71.000000pt" height="56.000000pt" viewBox="0 0 71.000000 56.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,56.000000) scale(0.100000,-0.100000)" fill={this.props.color} stroke="none">
                    <path d="M199 530 c-50 -27 -113 -101 -152 -179 -28 -58 -32 -75 -32 -151 0 -70 4 -91 22 -120 66 -106 190 -88 269 41 l37 60 28 -44 c34 -55 78 -87 129 -94 55 -8 85 2 131 44 83 75 93 156 21 178 -18 5 -30 -1 -56 -29 -44 -49 -74 -48 -109 4 -25 36 -21 66 12 89 93 66 73 224 -26 208 -19 -4 -49 -17 -65 -29 l-30 -24 -33 33 c-39 39 -89 43 -146 13z m130 -69 c7 -23 10 -48 7 -56 -9 -25 -33 -17 -46 15 -17 40 -44 43 -92 8 -37 -26 -36 -52 3 -68 72 -30 304 49 263 90 -6 6 -24 0 -48 -16 l-37 -26 15 31 c28 53 85 78 114 49 22 -22 14 -86 -13 -114 -53 -53 -217 -103 -294 -89 -92 16 -116 45 -86 108 34 72 131 135 180 117 12 -4 25 -24 34 -49z m-231 -164 c6 -7 11 -41 10 -76 -1 -51 3 -66 20 -84 21 -20 23 -20 56 -5 19 10 47 37 66 66 28 44 65 70 79 57 8 -8 -58 -130 -83 -154 -13 -12 -41 -29 -62 -36 -96 -33 -153 52 -124 188 12 58 19 67 38 44z m352 -42 c32 -96 98 -122 153 -60 36 42 57 45 57 9 0 -50 -54 -119 -100 -129 -34 -8 -95 17 -133 53 -24 22 -66 127 -55 136 41 34 65 31 78 -9z"/>
                </g>
            </svg>
        )
    }
}

export default Logo;