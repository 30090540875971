import React from 'react';
// import { withApollo } from 'react-apollo';

class Socials extends React.Component {
    state = {
        contribution: 1524,
        repositories: 22,
        followers: 168
    }

    componentDidMount() {
        // this.props.client.query({
        //     query: SocialCounts
        // })
    }

    render() {
        return (
            <section className="github">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <ul>
                                <li><i className="fab fa-github"></i> {this.state.contribution} contributions</li>
                                <li><i className="far fa-star"></i> {this.state.repositories} repositories</li>
                                <li><i className="fab fa-instagram"></i> {this.state.followers} followers</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Socials;