import React from 'react';
import { Link } from 'react-router-dom';

class Heading extends React.Component {
    state = {
        title: "A full-stack developer that builds amazing web apps.",
        subtitle: "A Toronto designer & developer that builds responsive web applications using HTML/CSS, JavaScript, React, jQuery, Apollo, GraphQL, NodeJS, Express, PostgreSQL, MongoDB and much more!",
        built: "Built on React v16.6.0 / Bootstrap v4.1.3 / Apollo & GraphQL",
        image: "images/header.png"
    };

    render() {
        let { props, state: { title, subtitle, built, image } } = this;
        if (props.title && props.subtitle && props.image) {
            title = props.title;
            subtitle = props.subtitle;
            image = props.image;
        }
        return (
            <div className="container heading">
                <div className="row">
                    <div className="col-lg-6">
                        <h1 >{title}</h1>
                        <h3 className="mt-3 mb-5" data-aos="fade-in">{subtitle}</h3>
                        <div>
                            <Link to="portfolio" className="btn btn-lg mr-3" data-aos="fade-left">View portfolio</Link>
                            <a href="/#mail" className="btn btn-lg btn-connect" data-aos="fade-right">Connect with Ollie</a>
                            <p className="mt-2" data-aos="fade-up">{built}</p>
                        </div>
                    </div>

                    <div className="col-lg-6 image-vertical-center" data-aos="fade-in">
                        <img src={image} alt={title}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Heading;