import React from 'react';

const Tools = [
    {
        id: 1,
        url: "images/Icon_PhpStorm.png",
        alt: "Built with Php Storm",
        paragraph: "PHPStorm is my go to IDE for PHP, Wordpress and Laravel development."
    },
    {
        id: 2,
        url: "images/Icon_Webstorm.png",
        alt: "Built with Web Storm",
        paragraph: "WebStorm is my favorite and ideal IDE for JavaScript and Node development."
    },
    {
        id: 3,
        url: "images/Icon_ToolBox.png",
        alt: "Built with Tool Box",
        paragraph: "JetBrain's tool box organizes & maintains my apps and projects."
    },
    {
        id: 4,
        url: "images/Icon_SourceTree.png",
        alt: "Built with Source Tree",
        paragraph: "Source Tree. A a Git client to manage and update my repositories."
    },
];

class Toolkit extends React.Component {
    render() {
        return (
            <section className="toolkit">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h2 data-aos="fade-in">The drive to develop and create anything</h2>
                            <h4 data-aos="fade-in">I'm always updating my toolbox for exciting new technologies that bring more flexibility from coding to testing so the end result is a rich experience for the people that use it!</h4>
                        </div>
                    </div>
    
                    <div className="row">
                        {Tools.map(tool => {
                            return (
                                <div className="col-lg-3 col-md-6 iconRevolve" key={tool.id} data-aos="fade-in">
                                    <img src={tool.url} alt={tool.alt}/>
                                    <p>{tool.paragraph}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        )
    }
}

export default Toolkit;