import React from 'react'
import { Logo } from '../../Global';
import { Link } from 'react-router-dom';

const FooterSocialMedia = [
    {
        id: 2,
        url: "//github.com/olliecee",
        fontAwesome: "fab fa-github-square"
    },
    {
        id: 3,
        url: "//www.behance.net/olliecee",
        fontAwesome: "fab fa-behance-square"
    },
    {
        id: 5,
        url: "//linkedin.com/in/olliecee",
        fontAwesome: "fab fa-linkedin"
    },
    {
        id: 6,
        url: "//angel.co/olliecee",
        fontAwesome: "fab fa-angellist"
    },
    {
        id: 7,
        url: "//stackoverflow.com/users/story/7571861",
        fontAwesome: "fab fa-stack-overflow"
    },
];
const FooterURL = [
    {
        name: "Portfolio",
        url: "portfolio",
        id: 1
    },
    {
        name: "Resume",
        url: "/resume",
        id: 2
    },
    {
        name: "Contact",
        url: "/#mail",
        id: 3
    }
];

class Footer extends React.Component {
    toggleModal = () => {
        console.warn('Open the modal');
    };

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-12">
                        <Link to="/" data-aos="fade-in">
                            <Logo color="#0275d8"/>
                        </Link>
                    </div>
                    <div className="col-lg-8 offset-lg-2 col-md-12 text-lg-right ollie-contact">
                        <ul data-aos="fade-in">
                            {FooterURL.map(item => {
                                if (item.id === 3) {
                                    return (
                                        <li key={item.id}>
                                            <a href={item.url} rel="noopener noreferrer">{item.name}</a>
                                        </li>
                                    )
                                }
                                return (
                                    <li key={item.id}>
                                        <Link to={item.url}>{item.name}</Link>
                                    </li>
                                )
                            })}
                            <li>
                                <ul className="social">
                                    {FooterSocialMedia.map(social => {
                                        return (
                                            <li key={social.id}>
                                                <a href={social.url} target="_blank" rel="noopener noreferrer">
                                                    <i className={social.fontAwesome}/>
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </li>
                        </ul>
                        <div className="clearfix"/>
                        <hr data-aos="fade-in" className="hidden-md-down"/>
                        <span data-aos="fade-in">© 2019 Ollie Cee, All Rights Reserved</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;