import React from 'react';
import { Link } from 'react-router-dom';

const AboutOllie = [
    {
        id: 1,
        imageSrc: "images/Changing.svg",
        title: "The Ever-changing Web",
        paragraph: "We've come a long way from tables and glossy button effects. I'll show you how to pick up on emerging design trends to keep you ahead of the curve.",
        callTo: "View Web App Demos",
        callToHref: "/portfolio",
    },
    {
        id: 2,
        imageSrc: "images/UI.svg",
        title: "User Interfaces",
        paragraph: "Having a knowledgable and seasoned graphic designer with front end development experience allows for a more cohesive end product or service.",
        callTo: "Explore Publications",
        callToHref: "/portfolio",
    },
    {
        id: 3,
        imageSrc: "images/Simple.svg",
        title: "Simplicity Trumps All",
        paragraph: "Over complicating the problem over complicates the solution. A focussed and strategic approach yields the most effective outcomes. Clean & simple.",
        callTo: "Check Out my Resume",
        callToHref: "/resume",
    },
];

class Philosophy extends React.Component {
    render() {
        return (
            <section className="proto">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="wobbly" data-aos="fade-in">
                                <svg>
                                    <use xlinkHref="#shape"></use>
                                </svg>
                            </div>
                            <div className="ad">
                                <img data-aos="fade-in" src="images/Proto2.png" alt="JetBrains Toolbox"/>
                                <img data-aos="fade-right" src="images/Proto.png" alt="JetBrains Toolbox"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h2 data-aos="fade-down">Fast prototyping.<br/>Concept to realization made possible.</h2>
                            <p data-aos="fade-in">Ollie possesses a toolbox of powerful skill-sets for quick and convenient prototyping of your projects. Your projects will contain up-to-date and modern web elements that is easily maintainable!</p>
                        </div>
                    </div>
                    <div className="row commercial">
                        {AboutOllie.map(item => {
                            return (
                                <div className="col-lg-4 col-md-4" key={item.id}>
                                    <img data-aos="fade-in" src={item.imageSrc} alt={item.title}/>
                                    <h3 data-aos="fade-down">{item.title}</h3>
                                    <p data-aos="fade-in">{item.paragraph}</p>
                                    <Link data-aos="fade-up" to={item.callToHref}><i className="fa fa-chevron-right"/> {item.callTo}</Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        )
    }
}

export default Philosophy;