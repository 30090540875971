import React from 'react';
import { Navigation } from '../Containers/Home/Header';
import Footer from '../Containers/Home/Footer';

export const WithHeaderFooter = (Component) => {
    return (
        <div id="noops">
            <header>
                <Navigation />
            </header>
            <Component />
            <footer>
                <Footer />
            </footer>
        </div>
    )
};
