import React, { useEffect } from 'react';
import AOS from 'aos';
import { ApolloClient } from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { Switch, Route } from 'react-router-dom';
import { Home, Portfolio, Resume, NotFound } from './Containers';
import { WithHeaderFooter } from './enhancers';

import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

const link = new BatchHttpLink({
    uri: `${process.env.REACT_APP_API}/graphql`,
    credentials: 'same-origin'
});

const client = new ApolloClient({
    link,
    cache: new InMemoryCache()
});

const PortfolioComplete = () => WithHeaderFooter(Portfolio);
const ResumeComplete = () => WithHeaderFooter(Resume);

const App = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <ApolloProvider client={client}>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/portfolio" component={PortfolioComplete} />
                <Route path="/resume" component={ResumeComplete} />
                <Route path="/*" component={NotFound} />
                {/* <Route path="/contact" component={Contact} />
                    <Route path="/login" component={Login} /> */}
            </Switch>
        </ApolloProvider>
    )
};

export default App;