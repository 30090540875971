import React from 'react'

class Resume extends React.Component {
    render() {
        return (
            <div className="container resume">
                <div className="row">
                    <section className="col-xl-8">
                        <h1>Work Experience</h1>
                        <article>
                            <h2>#paid, <span>Toronto</span></h2>
                            <h3>Full Stack Developer</h3>
                            <h4>February 2019 - September 2019</h4>
                            <p>Built the new SaaS product from the ground up in a more efficient and productive system architecture. This allows the Product Development Team to build faster, reducing errors and more scheduled release timings.</p>
                            <p>Increased web application availability globally, increased performance and increased security for all front end applications on 22 edge locations including expansions.</p>
                            <p>Increased deployment times for the application and introduced a more efficient QA and review processes.</p>
                            <p>Implemented and integrated popular ad services like Facebook and Instagram to automate Sales and Customer Support pipeline to help drive more sales.</p>
                            <p>Worked with many big brands like Toyota, Uber Eats, Good Foods, etc and increased overall satisfaction of the app by delivering fast hot fixes for our enterprise customers.</p>
                        </article>
                        <article>
                            <h2>Shoelace Inc (Shopify App), <span>Toronto</span></h2>
                            <h3>Front-End Developer</h3>
                            <h4>May 2017 - February 2019</h4>
                            <p>Lead the product design and development of the Shoelace app (2017-2019) into a new era of scalable technologies. Helped build the latest iteration of the Shoelace app (2019). Designed, developed and maintained the corporate site for 2017-2018. Built an entire custom Wordpress theme for Shoelace's blog.</p>
                            <p>Redesigned the entire "Preview" system for the 2017-2019 Shoelace App which helped inform customers about the value proposition that Shoelace was providing.</p>
                            <p>Successfully helped elevate & transition the companies brand image, product and position in the market place to be the leading Shopify app for retargeting by redesigning, redeveloping and bringing them into a future of the latest trending technologies & design.</p>
                            <ul>
                                <li>Developing in React, JavaScript, Babel, NodeJS, Express, MongoDB, Webpack/Gulp/Grunt, SASS/CSS, Docker</li>
                                <li>Ability to use many state management tools like Redux, MobX, and React's native state</li>
                                <li>Skills in vendor libraries like GraphQL, Apollo Client + Apollo Server, Axios, jQuery, Bootstrap</li>
                                <li>Experience with REST API and GraphQL/Apollo API</li>
                                <li>Implemented an isomorphic JS stack for 2017-2018 Shoelace app</li>
                                <li>Ability to create a CI/CD pipeline using CircleCI, BuddyWorks, AWS, Docker, NGINX and much more</li>
                                <li>Designed, developed and managed the custom Wordpress theme for the blog in MySQL and PHP</li>
                                <li>Experience with version control and open-sourcing code using Git and GitHub</li>
                                <li>Experience with PM2 / Serve / Docker containers & images. Able to build and deploy a cluster of APIs for greater reliability and performance for all applications</li>
                            </ul>
                        </article>
                        <article>
                            <h2>Red Academy, <span>Toronto</span></h2>
                            <h3>React/JavaScript & Wordpress/PHP TA</h3>
                            <h4>November 2018 - Present</h4>
                            <p>Assisting the next-generation of tech leaders by giving them the knowledge & guidance they need through teaching</p>
                            <ul>
                                <li>Facilitating participatory learning experiences and provide students the feedback and support they need on their journeys to become web developers</li>
                                <li>Working closely with other members of the instructional and operations team to deliver and evolve the curriculum while ensuring we provide a high-quality, positive learning experience for our students</li>
                                <li>Providing a deeply engaging, truly dynamic and highly rewarding work environment, where the team can thrive and find the highest possible levels of personal and professional fulfillment and satisfaction</li>
                            </ul>
                        </article>
                        <article>
                            <h2>Envato (Theme Forest), <span>Toronto</span></h2>
                            <h3>Wordpress Theme Developer</h3>
                            <h4>2016 - 2017</h4>
                            <p>Developed themes for theme giant Theme Forest (by Envato) in the popular CMS Wordpress.</p>
                            <ul>
                                <li>2+ years experience in developing Wordpress themes</li>
                                <li>Skills in HTML, CSS, and JavaScript with best-practices</li>
                                <li>Skills in vendor libraries like jQuery, Bootstrap and many more</li>
                                <li>Skills in JavaScript framework: React</li>
                            </ul>
                        </article>
                        <article>
                            <h2>Playcade Inc, <span>Toronto</span></h2>
                            <h3>Full Stack Developer</h3>
                            <h4>May 2015 - Sept 2016</h4>
                            <p>
                                Developed for a virtual reality start up that opened a VR entertainment center in the downtown core of Toronto. They provided an outlet for developers to expose their applications & games on a platform for consumers to try out.
                            </p>
                            <ul>
                                <li>Experience in developing, managing and deploying advertising & marketing campaigns, web landing pages, newsletters, API integration, utilizing AJAX requests and maintaining site with new web technologies</li>
                                <li>Lead proactive product and services that align with strategy including sales, marketing, provider networks, customer service and administration</li>
                                <li>Ability to absorb and facilitate advancements in technology within the virtual reality industry and propose ideas to innovate within the company's business model to advance position within the marketplace</li>
                            </ul>
                        </article>
                        <article>
                            <h2>The Creative Group, <span>Toronto</span></h2>
                            <h3>Front End Developer &amp; Web Designer</h3>
                            <h4>March 2016 - July 2016</h4>
                            <ul>
                                <li>Developed functional and appealing web and mobile-based applications based on usability</li>
                                <li>Created quality mockups and prototypes on tight timelines</li>
                                <li>Write functional requirement documents and specifications</li>
                                <li>Provided website maintenance and enhancements</li>
                                <li>Maintain graphic standards and branding throughout the product’s interface</li>
                            </ul>
                        </article>
                        <article>
                            <h2>Grand Slam Media, <span>Toronto</span></h2>
                            <h3>Web Development &amp; Graphic Design</h3>
                            <h4>March 2015 - March 2016</h4>
                            <h5>Responsibilities</h5>
                            <ul>
                                <li>Developed functional and appealing web and mobile-based applications based on usability</li>
                                <li>Created quality mockups and prototypes on tight timelines</li>
                                <li>Write functional requirement documents and specifications</li>
                                <li>Provided website maintenance and enhancements</li>
                                <li>Maintain graphic standards and branding throughout the product’s interface</li>
                            </ul>
    
                            <h5>Accomplishments</h5>
                            <ul>
                                <li>Created the landing & front page of one of North America's best advertising network</li>
                                <li>Published many designs for one of the largest magazines in the adult industry</li>
                                <li>Created a long lasting identity and brand through UX/UI design</li>
                                <li>Designed and developed a user-friendly, responsive, UI used by hundreds of popular Publisher & Advertisers</li>
                                <li>Strong knowledge in advertising and traffic such as CPM + CPMv models, PPC.</li>
                            </ul>
                        </article>
                        <h1>Education</h1>
                        <div className="education">
                            <article>
                                <h2>Sheridan College Institute of Technology, <span>Oakville</span></h2>
                                <h3>Web Design / Development</h3>
                                <h4>September 2010 - April 2011</h4>
                            </article>
                            <article>
                                <h3>Visual &amp; Creative Arts</h3>
                                <h4>September 2011 - April 2013</h4>
                            </article>
                            <article>
                                <h3>Computer Systems Technology: Software Development &amp; Network Engineering in Computer Science</h3>
                                <h4>September 2013 - April 2014</h4>
                            </article>
                        </div>
                        <h1>Publications</h1>
                        <article>
                            <h3>XBIZ Magazine, <span>Los Angeles</span></h3>
                            <h4>June 2015 - January 2016</h4>
                            <p>The adult industry's most popular publication is XBIZ Magazine. I had the opportunity of having my designs featured in XBIZ Magazine for several months straight. During the September issue I had the pleasure of being featured on the front and opening page of this prestigious magazine.</p>
                        </article>
                    </section>
                    <aside className="col-xl-4">
                        <h1>Technical Skills</h1>
                        <ul>
                            <li>HTML 5 + CSS 3, Boostrap</li>
                            <li>JavaScript, jQuery</li>
                            <li>React</li>
                            <li>NodeJS</li>
                            <li>ExpressJS</li>
                            <li>MongoDB</li>
                            <li>Docker Containers & Images</li>
                            <li>Apollo Client & Server</li>
                            <li>GraphQL</li>
                            <li>AJAX (JS and jQuery)</li>
                            <li>API integration</li>
                            <li>Webpack &amp; Babel</li>
                            <li>Webpack plugins &amp; loaders</li>
                            <li>AWS, DigitalOcean, Azure</li>
                            <li>CMS Theme Designer &amp; Developer for Wordpress</li>
                            <li>Git &amp; GitHub</li>
                            <li>Http-Server, Webpack-Dev-Server, WAMP</li>
                            <li>Familiar with JetBrain's IDE suite</li>
                            <li>Familiar with: Atoms, Brackets, VSCode</li>
                            <li>Search Engine Optimization</li>
                            <li>A/B Testing</li>
                        </ul>
                        <h1>Web Design Skills</h1>
                        <ul>
                            <li>Adobe Creative Suite (Photoshop, Illustrator, AE, etc)</li>
                            <li>Prototyping</li>
                            <li>Wireframing</li>
                            <li>Column / Grid Layouts</li>
                            <li>Email newsletter templating</li>
                            <li>Typography</li>
                            <li>UI Tools: Balsamiq, Invision</li>
                        </ul>
                        <h1>Skills</h1>
                        <ul>
                            <li>Graphic & Web Design</li>
                            <li>Reporting &amp; Analytics</li>
                            <li>Great Communication</li>
                            <li>Leadership Experience</li>
                            <li>Ability to Work in Teams</li>
                            <li>Ability to Work Individually</li>
                            <li>Able to Adapt to Environment</li>
                            <li>Worked in AGILE Environments</li>
                            <li>Strong Problem Solving Skills</li>
                            <li>Marketing & Advertising Experience</li>
                            <li>Ability to Manage Time Efficiently</li>
                            <li>Customer Service Experience</li>
                            <li>Strong Organizational Skills</li>
                        </ul>
                        <h1>Goals</h1>
                        <h2>Microsoft Certified Professional:</h2>
                        <h3>MTA:</h3>
                        <ul>
                            <li>IT Infrastructure</li>
                        </ul>
                        <h3>MCSA:</h3>
                        <ul>
                            <li>Windows Server 2016</li>
                        </ul>
                        <h3>MCSD:</h3>
                        <ul>
                            <li>Solutions Developer</li>
                        </ul>
                        <h3>MCSE:</h3>
                        <ul>
                            <li>Cloud Platform and Infrastructure</li>
                            <li>Server Infrastructure</li>
                        </ul>
                    </aside>
                </div>
            </div>
        )
    }
}

export default Resume;