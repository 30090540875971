import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../../../Global';

const Routes = [
    {
        url: '/portfolio',
        title: "Portfolio"
    },
    {
        url: '/resume',
        title: "Resume"
    },
    {
        url: '/',
        hash: '#mail',
        title: "Contact"
    },
];

class Navigation extends React.Component {
    render() {
        return (
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark p-0">
                    <Link to="/" className="navbar-brand">
                        <Logo color="#fff"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#olliebar" aria-controls="olliebar"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-end" id="olliebar">
                        <ul className="navbar-nav">
                            {Routes.map((route, index) => {
                                return (
                                    <li className="nav-item" key={index}>
                                        {route.hash ? (
                                            <a href={`${route.url}${route.hash}`} className="nav-link" rel="noopener noreferrer">{route.title}</a>
                                        ) : (
                                            <Link to={route.url} className="nav-link">{route.title}</Link>
                                        )}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </nav>
            </div>
        )
    }
}

export default Navigation;