import React from 'react';
import { Navigation, Heading, Socials } from '../Home/Header';
import Footer from '../Home/Footer';

export default class NotFound extends React.Component {
    render() {
        return (
            <div>
                <header>
                    <Navigation />
                    <Heading
                        title="😢 Oh no!"
                        subtitle="The page you're looking for has not been found. Please check out the fast-links below to navigate to parts of the site that do work. Sorry for the inconvenience and thank you for understanding!"
                        image="images/404.png"
                    />
                    <Socials />
                </header>

                <footer>
                    <Footer />
                </footer>
            </div>
        )
    }
}