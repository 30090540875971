import React from 'react';
import { Link } from 'react-router-dom';

const Actions = [
    {
        id: 1,
        imageSrc: "images/Portfolio.svg",
        anchorHref: "/portfolio",
        anchorTitle: "Visit my portfolio",
        content: "Visit my portfolio showcase and let your eyes explore my design and front-end development skills."
    },
    {
        id: 2,
        imageSrc: "images/Computer.svg",
        anchorHref: "/resume",
        anchorTitle: "View my resume",
        content: "Check out gigs I've been to, skills I currently possess and publications I've been featured in!"
    },
];

class CallToAction extends React.Component {
    render() {
        return (
            <section className="callTo">
                <div className="container">
                    <div className="row">
                        {Actions.map(action => {
                            return (
                                <div className="col-lg-6 col-md-12" key={action.id}>
                                    <img data-aos="fade-in" src={action.imageSrc} alt={action.anchorTitle}/>
                                    <span>
                                        <h2 data-aos="fade-down">
                                            <Link to={action.anchorHref}>{action.anchorTitle} <i className="fa fa-chevron-right"/></Link>
                                        </h2>
                                        <p data-aos="fade-in">{action.content}</p>
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        )
    }
}

export default CallToAction;