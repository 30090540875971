import React from 'react';
import LazyLoad from 'react-lazyload'
import { Publications, GraphicDesigns, WebPortfolio } from '../../constants/portfolio';

class Portfolio extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <section className="publicolio col">
                        <h4>Portfolio</h4>
                        <h5>Publications</h5>
                        <div className="row">
                            {Publications.map((pub, index) => {
                                return (
                                    <div className="col-xl-4 col-lg-6" key={index}>
                                        <div className="card mt-4">
                                            <a href={pub.url} target="_blank" rel="noopener noreferrer">
                                                <LazyLoad offset={100} height="420">
                                                    <img data-aos="fade-in" src={pub.image} alt={pub.title}/>
                                                </LazyLoad>
                                            </a>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </section>
                </div>
    
                <div className="row">
                    <section className="webfolio col">
                        <h5>Web Design &amp; Development</h5>
                        <div className="row">
                            {WebPortfolio.map((item, index) => {
                                let buttonHasUrl = <a href={item.urlDribble} target="_blank" className="btn btn-primary btn-block" rel="noopener noreferrer">View on Behance</a>;
                                if (!item.urlDribble || item.urlDribble === "#") {
                                    buttonHasUrl = <button className="btn btn-secondary btn-block" disabled>No Preview</button>
                                }
                                return (
                                    <div className="col-xl-4 col-lg-6 mt-4" key={index}>
                                        <div className="card h-100">
                                            <LazyLoad offset={100} height="420">
                                                <img className="card-img-top" data-aos="fade-in" src={item.urlImage} alt={item.title}/>
                                            </LazyLoad>
                                            <div className="card-body d-flex flex-column justify-content-between">
                                                <h5 className="card-title">{item.title}</h5>
                                                <p className="card-text">{item.info}</p>
                                                {buttonHasUrl}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </section>
                </div>
    
                <div className="row">
                    <section className="grapholio col">
                        <h5>Newsletter &amp; Social Media Campaigns</h5>
                        <div className="row">
                            {GraphicDesigns.map((designs, index) => {
                                return (
                                    <div className="col-xl-3 col-lg-6" key={index}>
                                        <div className="card mt-4">
                                            <LazyLoad offset={100} height="420">
                                                <img data-aos="fade-in" src={designs.image} alt={designs.title}/>
                                            </LazyLoad>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default Portfolio;